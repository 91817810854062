import React from 'react';

import SelectSingleFilter from './SelectSingleFilter/SelectSingleFilter';
import SelectMultipleFilter from './SelectMultipleFilter/SelectMultipleFilter';
import BookingDateRangeFilter from './BookingDateRangeFilter/BookingDateRangeFilter';
import KeywordFilter from './KeywordFilter/KeywordFilter';
import PriceFilter from './PriceFilter/PriceFilter';
import CustomFilter from './CustomFilter/CustomFilter';
import configData from '../../config';
import { useIntl } from 'react-intl';
/**
 * FilterComponent is used to map configured filter types
 * to actual filter components
 */
const FilterComponent = props => {
  const {
    idPrefix,
    filterConfig,
    urlQueryParams,
    initialValues,
    getHandleChangedValueFn,
    ...rest
  } = props;

  const intl = useIntl();
  let { id, type, queryParamNames, label, config, symbol } = filterConfig;

  const { liveEdit, showAsPopup } = rest;
  label = intl.formatMessage({ id: label });
  if (id === 'subCategory') {
    switch (urlQueryParams?.pub_category) {
      case 'notions':
        config['options'] = configData.custom.notionsSubCategory;
        type = 'SelectSingleFilter';
        break;
      case 'fabrics':
        config['options'] = configData.custom.fabricsSubCategory;
        type = 'SelectSingleFilter';
        break;
      case 'machinesTools':
        config['options'] = configData.custom.machinesToolsSubCategory;
        type = 'SelectSingleFilter';
        break;
    }
  }
  if (id === 'subSubCategory') {
    switch (urlQueryParams?.pub_subcategory) {
      case 'clothingFabrics':
        config['options'] = configData.custom.clothingFabricsSubSubCategory;
        break;
      case 'furnishingFabrics':
        config['options'] = configData.custom.furnishingFabricsSubSubCategory;
        break;
      case 'buttons':
        config['options'] = configData.custom.buttonsSubSubCategory;
        break;
      case 'trims':
        config['options'] = configData.custom.trimsSubSubCategory;
        break;
      case 'accessories':
        config['options'] = configData.custom.accessoriesSubSubCategory;
        break;
      case 'machines':
        config['options'] = configData.custom.machinesSubSubCategory;
        break;
      case 'tools':
        config['options'] = configData.custom.toolsSubSubCategory;
        break;
      case 'sewingStorage':
        config['options'] = configData.custom.sewingStorageSubSubCategory;
        break;
      case 'booksSewingPatterns':
        config['options'] = configData.custom.booksSewingPatternsSubSubCategory;
        break;
    }
  }

  const useHistoryPush = liveEdit || showAsPopup;
  const prefix = idPrefix || 'SearchPage';
  const componentId = `${prefix}.${id.toLowerCase()}`;
  const name = id.replace(/\s+/g, '-').toLowerCase();

  switch (type) {
    case 'SelectSingleFilter': {
      return (
        <SelectSingleFilter
          id={componentId}
          label={label}
          queryParamNames={queryParamNames}
          initialValues={initialValues(queryParamNames, liveEdit)}
          onSelect={getHandleChangedValueFn(useHistoryPush)}
          {...config}
          {...rest}
        />
      );
    }
    case 'SelectMultipleFilter': {
      return (
        <SelectMultipleFilter
          id={componentId}
          label={label}
          name={name}
          queryParamNames={queryParamNames}
          initialValues={initialValues(queryParamNames, liveEdit)}
          onSubmit={getHandleChangedValueFn(useHistoryPush)}
          {...config}
          {...rest}
        />
      );
    }
    case 'BookingDateRangeFilter': {
      return (
        <BookingDateRangeFilter
          id={componentId}
          label={label}
          queryParamNames={queryParamNames}
          initialValues={initialValues(queryParamNames, liveEdit)}
          onSubmit={getHandleChangedValueFn(useHistoryPush)}
          {...config}
          {...rest}
        />
      );
    }
    case 'PriceFilter': {
      return (
        <PriceFilter
          id={componentId}
          label={label}
          queryParamNames={queryParamNames}
          initialValues={initialValues(queryParamNames, liveEdit)}
          onSubmit={getHandleChangedValueFn(useHistoryPush)}
          {...config}
          {...rest}
        />
      );
    }
    case 'CustomFilter': {
      return (
        <CustomFilter
          id={componentId}
          label={label}
          queryParamNames={queryParamNames}
          initialValues={initialValues(queryParamNames, liveEdit)}
          onSubmit={getHandleChangedValueFn(useHistoryPush)}
          symbol={symbol}
          {...config}
          {...rest}
        />
      );
    }
    case 'KeywordFilter':
      return (
        <KeywordFilter
          id={componentId}
          label={label}
          name={name}
          queryParamNames={queryParamNames}
          initialValues={initialValues(queryParamNames, liveEdit)}
          onSubmit={getHandleChangedValueFn(useHistoryPush)}
          {...config}
          {...rest}
        />
      );
    default:
      return null;
  }
};

export default FilterComponent;
