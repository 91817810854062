import React from 'react';
import { bool, func, node, number, string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage, useIntl } from '../../../util/reactIntl';

import css from './MainPanelHeader.module.css';
import config from '../../../config';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

const MainPanelHeader = props => {
  const {
    rootClassName,
    className,
    children,
    sortByComponent,
    listingsAreLoaded,
    resultsCount,
    searchInProgress,
    noResultsInfo,
    urlQueryParams,
  } = props;
  const intl = useIntl();
  const history = useHistory();
  const classes = classNames(rootClassName || css.root, className);
  const category = urlQueryParams?.pub_category;
  const subCategory = urlQueryParams?.pub_subcategory;
  const productType = urlQueryParams?.pub_subSubCategory;
  const categoryLabel = category
    ? intl
        .formatMessage({ id: config.custom.categories?.find(c => c.key === category)?.label })
        ?.toLowerCase()
    : null;
  const subCategoryLabel = subCategory
    ? intl
        .formatMessage({ id: config.custom.subCategories?.find(c => c.key === subCategory)?.label })
        ?.toLowerCase()
    : null;
  const productTypeLabel = productType
    ? intl
        .formatMessage({
          id: config.custom.subSubCategories?.find(c => c.key === productType)?.label,
        })
        ?.toLowerCase()
    : null;
  const title = productTypeLabel || subCategoryLabel || categoryLabel;
  return (
    <div className={classes}>
      <div className={css.breadCrumbs}>
        {categoryLabel ? (
          <span
            className={css.breadCrumb}
            onClick={() => {
              history.push({
                search: `?pub_category=${category}`,
              });
            }}
          >
            <span className={css.breadCrumbLink}>{categoryLabel}</span>
          </span>
        ) : null}
        {subCategoryLabel ? (
          <span
            className={css.breadCrumb}
            onClick={() => {
              history.push({
                search: `?pub_category=${category}&pub_subcategory=${subCategory}`,
              });
            }}
          >
            /<span className={css.breadCrumbLink}>{subCategoryLabel}</span>
          </span>
        ) : null}
        {productTypeLabel ? (
          <span
            className={css.breadCrumb}
            onClick={() => {
              history.push({
                search: `?pub_category=${category}&pub_subcategory=${subCategory}&pub_subSubCategory=${productType}`,
              });
            }}
          >
            /<span className={css.breadCrumbLink}>{productTypeLabel}</span>
          </span>
        ) : null}
      </div>
      <h1 className={css.title}>{title}</h1>
      <div className={css.searchOptions}>
        <div className={css.searchResultSummary}>
          <h2 className={css.resultsFound}>
            {searchInProgress ? (
              <FormattedMessage id="MainPanelHeader.loadingResults" />
            ) : (
              <FormattedMessage
                id="MainPanelHeader.foundResults"
                values={{ count: resultsCount }}
              />
            )}
          </h2>
        </div>
        <div className={css.sortyByWrapper}>
          <span className={css.sortyBy}>
            <FormattedMessage id="MainPanelHeader.sortBy" />
          </span>
          {sortByComponent}
        </div>
      </div>
      {children}
      {noResultsInfo ? noResultsInfo : null}
    </div>
  );
};

MainPanelHeader.defaultProps = {
  rootClassName: null,
  className: null,
  resultsCount: null,
  searchInProgress: false,
  sortByComponent: null,
};

MainPanelHeader.propTypes = {
  rootClassName: string,
  className: string,
  listingsAreLoaded: bool.isRequired,
  resultsCount: number,
  searchInProgress: bool,
  sortByComponent: node,
};

export default MainPanelHeader;
